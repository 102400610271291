import { clsx } from "clsx";
type ArticleTeaserHeadingProps = {
  readonly className?: string;
  readonly title: string;
};
export function ArticleTeaserHeading({
  className,
  title
}: ArticleTeaserHeadingProps) {
  return <h1 className={clsx("hyphens-manual break-words font-heading", className)} dangerouslySetInnerHTML={{
    __html: title
  }} data-k5a-pos="head" data-sentry-component="ArticleTeaserHeading" data-sentry-source-file="ArticleTeaserHeading.component.tsx" />;
}