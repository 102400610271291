"use client";

import { Link } from "@/components/Link/Link.component";
import { Translation } from "@/components/Translation/Translation.component";
import { useSite } from "@/contexts/site/site.context";
type ProductSectionProps = {
  readonly priceWithCurrency: string;
  readonly url: string;
};
export function ProductSection({
  priceWithCurrency,
  url
}: ProductSectionProps) {
  const {
    name
  } = useSite();
  return <div className="py-3" data-sentry-component="ProductSection" data-sentry-source-file="ProductSection.component.tsx">
      <div className="border-b border-solid border-silver pb-2">
        <h1 className="pb-2 font-heading text-2xl font-theme-bold lg:text-3xl">
          <Translation da={`Få ubegrænset adgang til ${name} for ${priceWithCurrency} per kvartal`} de={`Erhalten Sie unbegrenzten Zugang zu ${name} für ${priceWithCurrency} pro Quartal`} en={`Get unlimited access to ${name} for ${priceWithCurrency} quarterly`} no={`Få ubegrenset tilgang til ${name} for ${priceWithCurrency} per kvartal`} sv={`Få obegränsad tillgång till ${name} för ${priceWithCurrency} per kvartal`} data-sentry-element="Translation" data-sentry-source-file="ProductSection.component.tsx" />
        </h1>
        <Link className="text-primary hover:underline" href={url} position="bbtn" data-sentry-element="Link" data-sentry-source-file="ProductSection.component.tsx">
          <Translation da="Start dit personlige abonnement her" de="Starten Sie hier Ihr persönliches Abonnement" en="Start your personal subscription here" no="Start ditt personlige abonnement her" sv="Starta ditt personliga abonnemang här" data-sentry-element="Translation" data-sentry-source-file="ProductSection.component.tsx" />
        </Link>
      </div>
      <div className="py-3">
        <h1 className="pb-2 font-heading text-2xl font-theme-bold lg:text-3xl">
          <Translation da="Få et skræddersyet abonnement, som passer til netop din virksomhed" de="Erhalten Sie ein maßgeschneidertes Abonnement, das genau zu Ihrem Unternehmen passt" en="Get a tailored subscription that fits your business perfectly" no="Få et skreddersydd abonnement som passer akkurat til din bedrift" sv="Få ett skräddarsytt abonnemang som passar just ditt företag" data-sentry-element="Translation" data-sentry-source-file="ProductSection.component.tsx" />
        </h1>

        <Link className="text-primary hover:underline" href={`https://subscriptions.watchmedier.com/${name}`} position="corpsub" data-sentry-element="Link" data-sentry-source-file="ProductSection.component.tsx">
          <Translation da="Læs mere her" de="Lesen Sie hier mehr" en="Read more here" no="Les mer her" sv="Läs mer här" data-sentry-element="Translation" data-sentry-source-file="ProductSection.component.tsx" />
        </Link>
      </div>
    </div>;
}