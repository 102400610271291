import { clsx } from "clsx";
import type { ArticleTeaserModel } from "@/app/(sites)/_models/article-teaser-model.types";
import { Link } from "@/components/Link/Link.component";
import { List } from "@/components/List/List.component";
import { ListItem } from "@/components/List/ListItem.component";
import { Translation } from "../Translation/Translation.component";
import type { Translations } from "../Translation/translation.types";
type RelatedArticlesProps = {
  readonly className?: string;
  readonly relatedArticles: ReadonlyArray<ArticleTeaserModel.RelatedArticle>;
  readonly isInverted?: boolean;
  readonly prefix?: Translations | null;
  readonly type?: "bullet";
};
export function RelatedArticles({
  className,
  relatedArticles,
  isInverted = false,
  prefix = null,
  type
}: RelatedArticlesProps) {
  return <List className={clsx(className, "space-y-2 text-sm text-inherit")} tag="section" type={type} data-sentry-element="List" data-sentry-component="RelatedArticles" data-sentry-source-file="RelatedArticles.component.tsx">
      {relatedArticles.map(({
      targetUrl,
      title
    }, index) => <ListItem className={clsx(isInverted && "marker:text-white")} key={targetUrl} position={`rart${index + 1}`} tag="article">
          <>
            {prefix !== null ? <span className={clsx(isInverted ? "text-inherit" : "text-primary")}>
                <Translation model={prefix} />
              </span> : null}
            <Link className="hover:underline" href={targetUrl}>
              <span dangerouslySetInnerHTML={{
            __html: title.html
          }} />
            </Link>
          </>
        </ListItem>)}
    </List>;
}